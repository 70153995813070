const emoji = [
  '🍔',
  '🐔',
  '❤️',
  '🎁',
  '✨',
  '✅',
  '🔥',
  '😊',
  '😂',
  '😉',
  '🎉',
  '💀',
  '👍',
  '👉',
  '👌',
  '👀',
  '🚀',
  '😍',
  '🤔',
  '🍆',
  '💩',
  '🐟',
  '🛏️',
  '💤',
];

export default emoji;
